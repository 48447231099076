<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <Header title="ylb"></Header>
    <BannerTitle title="ylb"></BannerTitle>
    <div class="ylbBox">
      <div class="firBox">
        <img src="@/assets/images/v2/ylb/ylb-logo.png" alt="">
        <p>{{ $t("ylb.listTop.topDesc") }}</p>
      </div>
      <div class="secBox">
        <div class="sec">
          <div class="Sectit">{{ $t("ylb.SecList.SecTit") }}</div>
          <div class="picBox">
            <img src="@/assets/images/v2/ylb/ylb-1.png" v-show="$i18n.locale == 'zh'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-2.png" v-show="$i18n.locale == 'zh'"   alt="">
            <img src="@/assets/images/v2/ylb/ylb-3.png" v-show="$i18n.locale == 'zh'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-en1.png" v-show="$i18n.locale == 'en'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-en2.png" v-show="$i18n.locale == 'en'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-en3.png" v-show="$i18n.locale == 'en'"  alt="">
          </div>
        </div>
      </div>
      <div class="ThirdBox">
        <div class="Thirdtit">{{ $t("ylb.ThirdList.title") }}</div>
        <ul>
          <li>
            <img src="@/assets/images/v2/ylb/ylb-5.png" alt="">
            <b>{{ $t("ylb.ThirdList.tips1") }}</b>
            <span>{{ $t("ylb.ThirdList.desc1") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ylb/ylb-6.png" alt="">
            <b>{{ $t("ylb.ThirdList.tips2") }}</b>
            <span>{{ $t("ylb.ThirdList.desc2") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ylb/ylb-7.png" alt="">
            <b>{{ $t("ylb.ThirdList.tips3") }}</b>
            <span>{{ $t("ylb.ThirdList.desc3") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ylb/ylb-8.png" alt="">
            <b>{{ $t("ylb.ThirdList.tips4") }}</b>
            <span>{{ $t("ylb.ThirdList.desc4") }}</span>
          </li>
        </ul>
      </div>
      <div class="FourBox">
        <div class="Four">
          <div class="Sectit">{{ $t("ylb.FourList.title") }}</div>
          <div class="picBox">
            <img src="@/assets/images/v2/ylb/ylb-9.png" v-show="$i18n.locale == 'zh'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-10.png" v-show="$i18n.locale == 'zh'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-en9.png" v-show="$i18n.locale == 'en'"  alt="">
            <img src="@/assets/images/v2/ylb/ylb-en10.png" v-show="$i18n.locale == 'en'"  alt="">
          </div>
        </div>
      </div>
      <div class="FdBox">
        <div class="Sectit">{{ $t("ylb.ForList.title") }}</div>
        <ul>
          <li>
            <img  src="@/assets/images/v2/ylb/ylb-11.png" alt="">
          </li>
          <li>
            <img  src="@/assets/images/v2/ylb/ylb-12.png" alt="">
          </li>
          <li>
            <img  src="@/assets/images/v2/ylb/ylb-13.png" alt="">
          </li>
          <li>
            <img  src="@/assets/images/v2/ylb/ylb-14.png" alt="">
          </li>
        </ul>
      </div>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "linyuan",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
  }
};
</script>

<style lang="scss">
.ylbBox {
  .FdBox {
    width: 1420px;
    margin: 0 auto;
    padding: 100px 0;

    .Sectit {
        height:56px;
        font-size:40px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:56px;
        text-align: center;
        margin-bottom: 60px;
      }

      ul {
        display: flex;
        align-items: center;
        justify-items: center;

        li {
          img {
            width:375px;
            height:612px;
          }
        }
      }

  }

  .FourBox {
    background:rgba(243,246,249,1);
    padding: 100px 0;

    .Four {
      width: 1300px;
      margin: 0 auto;

      .Sectit {
        height:56px;
        font-size:40px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:56px;
        text-align: center;
        margin-bottom: 30px;
      }

      .picBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width:626px;
          height:580px;
        }
      }
    }


  }

  .ThirdBox {
    width: 1300px;
    margin: 0 auto;
    margin-top: 70px;
    padding: 100px 0;

    .Thirdtit {
      height:56px;
      font-size:40px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(51,51,51,1);
      line-height:56px;
      text-align: center;
      margin-bottom: 106px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;

        b {
          height:28px;
          font-size:20px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          color:rgba(51,51,51,1);
          line-height:28px;
          margin-bottom: 13px;
        }

        img {
          width:72px;
          height:72px;
        }

        span {
          width:210px;
          height: 208px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(153,153,153,1);
          line-height:26px;
          text-align: center;
        }
      }
    }

  }

  .secBox {
    background:rgba(243,246,249,1);
    padding: 100px 0;

    .sec {
      width: 1300px;
      margin: 0 auto;

      .Sectit {
        height:56px;
        font-size:40px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:56px;
        text-align: center;
        margin-bottom: 30px;
      }

      .picBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width:449px;
          height:557px;
        }
      }


    }
  }

  .firBox {
    width: 1000px;
    margin: auto;
    display: flex;
    align-items: center;
    padding-bottom: 100px;
    justify-content: space-between;

    img {
      width:220px;
      height:67px;
    }
    p {
      width:720px;
      font-size:16px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(102,102,102,1);
      line-height:30px;
    }
  }

}
</style>
